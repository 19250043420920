import React from "react";
import "./contact.scss";
import Footer from "../Footers/Footer";
import Form from "../Form/Form";
function Contact() {
  return (
    <div id="contact">
      <div
        className="content"
        style={{
          background: 'url("assets/images/Footer/hero.png")',
        }}
      >
        <div className="style">
          <article>
            <div className="text">
              <h1>Got something you want to talk about?</h1>
              <h3>General or App Related issues</h3>
              <p>For feedback or issues related to the app:</p>
              <div className="contact">
                <p>contact@yourmail.com </p>
                <p> 044 25950366</p>
              </div>
              <div className="Address">
                <h4>Address</h4>
                <p>No.7, Kummalamman Koil Street,3rd lane,</p>
                <p>Tondiarpet, Chennai - 600 081</p>
              </div>
            </div>
          </article>
          <Form />
        </div>
      </div>
      <Footer />
    </div>
  );
}
export default Contact;
