import React, { useState } from "react";
import emailjs from "emailjs-com";
import "./form.scss";

const Form = () => {
  const initialValues = {
    firstname: "",
    lastname: "",
    mobilenumber: "",
    Email: "",
    Location: "",
    countrycode: "",
    Attach: "",
  };

  const [formValues, setFormValues] = useState(initialValues);
  const [formErrors, setFormErrors] = useState({});
  const [isSubmit, setIsSubmit] = useState(false);
  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormValues({ ...formValues, [name]: value });
  };
  const handleSubmit = (e) => {
    e.preventDefault();
    const errors = validate(formValues);
    setFormErrors(errors);
    setIsSubmit(true);
    if (Object.keys(errors).length === 0) {
      emailjs
        .send(
          "service_p8kyqqs",
          "template_mub2gei",
          formValues,
          "txsKij4Vb98xW5be5"
        )
        .then((response) => {
          alert("The Form submitted successfully.");
          console.log("Email sent successfully!", response);
        })
        .catch((error) => {
          alert("Error sending email: " + error);
          console.error("Error sending email:", error);
        });
    } else {
      alert("Kindly enter the required fields");
      console.log("Form has errors. Submit prevented.");
    }
  };
  const validate = (values) => {
    const errors = {};
    const regex =
      /^[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)*$/;
    if (!values.firstname) {
      errors.firstname = "FirstName is required!";
    }
    if (!values.lastname) {
      errors.lastname = "LastName is required!";
    }

    if (!values.Email) {
      errors.Email = "Email is required!";
    } else if (!regex.test(values.Email)) {
      errors.Email = "This is not a valid email format!";
    }

    if (!values.mobilenumber) {
      errors.mobilenumber = "Mobile number is required!";
    } else if (!/^\d{10}$/.test(values.mobilenumber)) {
      errors.mobilenumber = "Mobile number should contain exactly 10 numbers!";
    }

    if (!values.Location) {
      errors.Location = "Location is required!";
    }

    // if (!values.Attach) {
    //   errors.Attach = "Attachement is required!";
    // }
    return errors;
  };
  return (
    <div className="Main">
      <form onSubmit={handleSubmit}>
        <div>
          <div>
            <label>Name</label>
            <div>
              <input
                type="text"
                name="firstname"
                placeholder="Enter Name"
                value={formValues.firstname}
                onChange={handleChange}
              />
              <p className="error">{formErrors.firstname}</p>
            </div>
          </div>

          {/* <div>
            <label>Last Name</label>
            <div>
              <input
                type="text"
                name="lastname"
                placeholder="Enter Last Name"
                value={formValues.lastname}
                onChange={handleChange}
              />
              <p className="error">{formErrors.lastname}</p>
            </div>
          </div> */}
        </div>

        <div className="seconds">
          <label>Mobile Number</label>
          <div>
            <input
              className="number"
              type="text"
              name="countrycode"
              placeholder="+91"
              value={formValues.countrycode}
              onChange={handleChange}
            />
            <input
              className="mobilenumber"
              type="text"
              name="mobilenumber"
              placeholder="Mobile Number"
              value={formValues.mobilenumber}
              onChange={handleChange}
            />
          </div>
          <p className="error">{formErrors.mobilenumber}</p>
        </div>
        <div className="Mail">
          <div>
            <label>Email</label>
            <div>
              <input
                className="mail-id"
                type="text"
                name="Email"
                placeholder="Email"
                value={formValues.Email}
                onChange={handleChange}
              />
              <p className="error">{formErrors.Email}</p>
            </div>
          </div>
        </div>
        <div className="Location"> 
        <div>
              <label>Location</label>
              <div>
                <input
                  type="text"
                  name="Location"
                  placeholder="Location"
                  value={formValues.Location}
                  onChange={handleChange}
                />
                <p className="error">{formErrors.Location}</p>
              </div>
            </div>
</div>
        {/* <div>
              <label>Attach</label>
              <div>
                
                <input
                  type=""
                  name="Attach"
                  placeholder=" Attach"
                  value={formValues.Attach}
                  onChange={handleImage}
                />
                <p className="error">{formErrors.Attach}</p>
              </div>
            </div> */}
        {/* </div> */}
        <div className="btn">
          <button>Send Query</button>
        </div>
      </form>
    </div>
  );
};

export default Form;
