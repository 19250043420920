export const planStaticData = [
  {
    id: 1,
    mode: "",
    type: "special",
    title: "Standard",
    month: "03",
    price: "3675",
    lists: [
      {
        id: 1,
        status: "active",
        text: "Send Unlimited Messages",
      },
      {
        id: 2,
        status: "active",
        text: "View upto 75 Contact Numbers",
      },
      {
        id: 3,
        status: "inactive",
        text: "Standout from other profiles",
      },
      {
        id: 4,
        status: "inactive",
        text: "Let matches contact you directly",
      },
    ],
  },
  {
    id: 2,
    mode: "active",
    type: "special",
    title: "Elite",
    month: "03",
    price: "6500",
    lists: [
      {
        id: 1,
        status: "active",
        text: "Send Unlimited Messages",
      },
      {
        id: 2,
        status: "active",
        text: "View upto 75 Contact Numbers",
      },
      {
        id: 3,
        status: "active",
        text: "Standout from other profiles",
      },
      {
        id: 4,
        status: "active",
        text: "Let matches contact you directly",
      },
    ],
  },
  {
    id: 3,
    mode: "",
    type: "special",
    title: "Premium",
    month: "03",
    price: "5489",
    lists: [
      {
        id: 1,
        status: "active",
        text: "Send Unlimited Messages",
      },
      {
        id: 2,
        status: "active",
        text: "View upto 75 Contact Numbers",
      },
      {
        id: 3,
        status: "active",
        text: "Standout from other profiles",
      },
      {
        id: 4,
        status: "active",
        text: "Let matches contact you directly",
      },
    ],
  },
]
