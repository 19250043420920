export const HoverCardStaticData = [
  {
    id: 1,
    title: "Brides",
    direction: "ltr",
    text: "A women with a fine prospect of happiness behind her.",
    cards: [
      {
        id: 1,
        personId: "IMB0001",
        img: "img1",
        name: "Aparna Devi",
        height: "33Yrs, 5 feet 6 inches",
        address: "Mumbai, Maharashtra",
      },
      {
        id: 2,
        personId: "IMB0002",
        img: "img2",
        name: "Aparna Devi",
        height: "33Yrs, 5 feet 6 inches",
        address: "Mumbai, Maharashtra",
      },
      {
        id: 3,
        personId: "IMB0003",
        img: "img3",
        name: "Aparna Devi",
        height: "33Yrs, 5 feet 6 inches",
        address: "Mumbai, Maharashtra",
      },
      {
        id: 4,
        personId: "IMB0004",
        img: "img4",
        name: "Aparna Devi",
        height: "33Yrs, 5 feet 6 inches",
        address: "Mumbai, Maharashtra",
      },
      {
        id: 5,
        personId: "IMB0005",
        img: "img5",
        name: "Aparna Devi",
        height: "33Yrs, 5 feet 6 inches",
        address: "Mumbai, Maharashtra",
      },
      {
        id: 6,
        personId: "IMB0006",
        img: "img6",
        name: "Aparna Devi",
        height: "33Yrs, 5 feet 6 inches",
        address: "Mumbai, Maharashtra",
      },
      {
        id: 7,
        personId: "IMB0007",
        img: "img7",
        name: "Aparna Devi",
        height: "33Yrs, 5 feet 6 inches",
        address: "Mumbai, Maharashtra",
      },
      {
        id: 8,
        personId: "IMB0008",
        img: "img8",
        name: "Aparna Devi",
        height: "33Yrs, 5 feet 6 inches",
        address: "Mumbai, Maharashtra",
      },
    ],
  },
  {
    id: 2,
    direction: "rtl",
    title: "Grooms",
    text: "Being happy never goes out of style.",
    cards: [
      {
        id: 1,
        personId: "IMG0001",
        img: "groom1",
        name: "Aparna Devan",
        height: "33Yrs, 5 feet 6 inches",
        address: "Mumbai, Maharashtra",
      },
      {
        id: 2,
        personId: "IMG0002",
        img: "groom2",
        name: "Aparna Devan",
        height: "33Yrs, 5 feet 6 inches",
        address: "Mumbai, Maharashtra",
      },
      {
        id: 3,
        personId: "IMG0003",
        img: "groom3",
        name: "Aparna Devan",
        height: "33Yrs, 5 feet 6 inches",
        address: "Mumbai, Maharashtra",
      },
      {
        id: 4,
        personId: "IMG0004",
        img: "groom4",
        name: "Aparna Devan",
        height: "33Yrs, 5 feet 6 inches",
        address: "Mumbai, Maharashtra",
      },
      {
        id: 5,
        personId: "IMG0005",
        img: "groom5",
        name: "Aparna Devan",
        height: "33Yrs, 5 feet 6 inches",
        address: "Mumbai, Maharashtra",
      },
      {
        id: 6,
        personId: "IMG0006",
        img: "groom6",
        name: "Aparna Devan",
        height: "33Yrs, 5 feet 6 inches",
        address: "Mumbai, Maharashtra",
      },
      {
        id: 7,
        personId: "IMG0007",
        img: "groom7",
        name: "Aparna Devan",
        height: "33Yrs, 5 feet 6 inches",
        address: "Mumbai, Maharashtra",
      },
      {
        id: 8,
        personId: "IMG0008",
        img: "groom8",
        name: "Aparna Devan",
        height: "33Yrs, 5 feet 6 inches",
        address: "Mumbai, Maharashtra",
      },
    ],
  },
]
