export const CarouselData = [
  {
    id: 1,
    img: "img1",
  },
  {
    id: 2,
    img: "img2",
  },
  {
    id: 3,
    img: "img3",
  },
  {
    id: 4,
    img: "img4",
  },
  {
    id: 5,
    img: "img5",
  },
  {
    id: 6,
    img: "img6",
  },
  {
    id: 7,
    img: "img7",
  },
  {
    id: 8,
    img: "img8",
  },
  {
    id: 9,
    img: "img9",
  },
  {
    id: 10,
    img: "img10",
  },
  {
    id: 11,
    img: "img11",
  },
  {
    id: 12,
    img: "img12",
  },
  {
    id: 13,
    img: "img13",
  },
  {
    id: 14,
    img: "img14",
  },
  {
    id: 15,
    img: "img15",
  },
  {
    id: 16,
    img: "img16",
  },
  {
    id: 17,
    img: "img17",
  },
  {
    id: 18,
    img: "img18",
  },
  {
    id: 19,
    img: "img19",
  },
  {
    id: 20,
    img: "img20",
  },
  {
    id: 21,
    img: "img21",
  },
  {
    id: 22,
    img: "img22",
  },
  {
    id: 23,
    img: "img23",
  },
  {
    id: 24,
    img: "img24",
  },
]
