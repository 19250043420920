import React from "react"
import "./about.scss"

const About = () => {
  return (
    <div
      id='about'
      className='about'
      style={{
        background: 'url("assets/images/about/bg.png")',
      }}>
      <div>
        <img
          className='Layer'
          src='assets/images/about/Layer_1(1).png'
          alt='layer'
        />

        <article className='index'>
          <div>
            <h2>Infinit Matrimony</h2>
            <p>
              At Infinit Matrimony, we believe that every person deserves to find
              a life partner who complements them perfectly, understands their
              aspirations, and shares their journey through the beautiful
              adventure of marriage. We are dedicated to creating a space where
              individuals can connect, discover, and build meaningful
              relationships that last a lifetime.
            </p>
            <p>
              Founded with the vision of bringing happiness and harmony to
              people's lives, Infinit Matrimony is committed to revolutionizing
              the way people find their life partners.
            </p>
            <button>Find your Life partner now!</button>
          </div>
          <img src='assets/images/about/Group 14.png' alt='' />
        </article>
        <img
          className='Rect'
          src='assets/images/about/Layer_1.png'
          alt='layer'
        />
      </div>
    </div>
  )
}

export default About
