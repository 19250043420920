import { faCircleCheck, faXmark } from "@fortawesome/free-solid-svg-icons"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import React from "react"

const PlanCard = ({ slide, ...data }) => {
  return (
    <div
      className={
        slide
          ? "plan-card plan-card2"
          : slide && data.mode === "active"
          ? "plan-card plan-card2 active-plan-card"
          : data.mode === "active"
          ? "plan-card active-plan-card"
          : "plan-card"
      }
      key={data.id}>
      <h3>{data.mode === "active" && <span>{data.type}</span>}</h3>
      <div
        className='plan-card-body'
        style={{
          background: `url('assets/images/about/${
            data.mode === "active" ? "Red" : "planBg"
          }.png') no-repeat center`,
          backgroundSize: "contain",
        }}>
        <div className='card-title'>
          <div>
            <img
              src={`assets/images/about/${
                data.mode === "active" ? "dimond" : "dimond1"
              }.png`}
              alt=''
            />
            <div>
              <h4>{data.title}</h4>
              <h6>
                <span>{data.month}</span>
                Months
              </h6>
            </div>
          </div>
          <h5>₹ {data.price}</h5>
        </div>
        <div className='card-body'>
          {data.lists?.map((list) => {
            const { id, status, text } = list
            return (
              <div key={id}>
                <>
                  {status === "active" ? (
                    <FontAwesomeIcon
                      icon={faCircleCheck}
                      className='checkmark'
                    />
                  ) : (
                    <FontAwesomeIcon icon={faXmark} className='xmark' />
                  )}
                  <p>{text}</p>
                </>
              </div>
            )
          })}
        </div>
        <div className='plan-btn'>
          <button>Continue</button>
        </div>
      </div>
    </div>
  )
}

export default PlanCard
