import React from "react"
import "./carousel.scss"
import { Splide, SplideSlide } from "@splidejs/react-splide"
import "@splidejs/react-splide/css"

const Carousel = ({ cards, direction }) => {
  return (
    <Splide
      className='profile-cards'
      aria-label='My Favorite Images'
      options={{
        rewind: true,
        autoplay: true,
        arrows: false,
        type: "loop",
        width: 750,
        height: "400px",
        perPage: 3,
        perMove: 1,
        direction: direction,
        drag: true,
        gap: "1rem",
        easing: "linear",
        pagination: false,
        breakpoints: {
          768: {
            width: 500,
            perPage: 2,
          },
          499: {
            width: 300,
            perPage: 1,
          },
        },
      }}>
      {cards?.map((card) => (
        <SplideSlide>
          <div className='profile-card'>
            <div key={card?.id}>
              <span>{card?.personId}</span>
              <img src={`assets/images/hoverCard/${card?.img}.png`} alt='' />
              <h2>{card?.name}</h2>
              <h4>{card?.height}</h4>
              <h4>{card?.address}</h4>
            </div>
          </div>
        </SplideSlide>
      ))}
    </Splide>
  )
}

export default Carousel
