import React, { useState, useEffect } from "react";
import "./accordion.scss";

const Accordion = () => {
  const [faqData, setFaqData] = useState([]);

  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await fetch(
          "https://royal-matrimoni-api-c5tbent7ka-el.a.run.app/api/v1/site_info"
        );
        if (!response.ok) {
          throw new Error("Network response was not ok");
        }
        const data = await response.json();
        setFaqData(data);
      } catch (error) {
        console.log(error);
      }
    };

    fetchData();
  }, []);

  const AccordionSection = ({ data, index }) => {
    const [expanded, setExpanded] = useState(false);

    const toggleSection = () => {
      setExpanded(!expanded);
    };

    return (
      <div
        className={`accordion ${expanded ? "show" : ""}`}
        onClick={toggleSection}
      >
        <div>
          <h3>
           {index + 1} - {data.question} 
          </h3>
          <span>{expanded ? "-" : "+"}</span>
        </div>
        <p className={expanded ? "show" : ""}>{data.answer}</p>
      </div>
    );
  };

  return (
    <div className="accordions">
      <article>
        <div className="title">
          <span>?</span>
          <h2>Frequently Asked Questions</h2>
        </div>
        <div className="accordion-wrapper">
          {faqData.map((info, i) => (
            <div key={i}>
              {info.faq.map((data, ind) => (
                <AccordionSection index={ind} key={data.id} data={data}  />
              ))}
            </div>
          ))}
        </div>
      </article>
    </div>
  );
};

export default Accordion;



