import React from "react"
import "./stories.scss"
import Carousel from "./components/carousel/Carousel"

const Stories = () => {
  return (
    <div className='carousel-wrapper' id='stories'>
      <article>
        <h2>
          Thousands of <span className='gradient-text'> Success</span> Stories
        </h2>
        <p>
          At Infinit Matrimony, we are proud to have facilitated thousands of
          success stories, where individuals have found their life partners and
          embarked on a journey of love, happiness, and companionship. Here are
          just a few of the many heartwarming stories that have unfolded through
          our platform
        </p>
      </article>
      <Carousel />
    </div>
  )
}

export default Stories
