import React, { useState } from "react"
import "./hoverCard.scss"
// import { HoverCardStaticData } from "./HoverCradStaticData"

const HoverCard = ({ cards }) => {
  const [rightId, setRightId] = useState(0)

  const slideRight = (id) => {
    setRightId(id)
  }

  return (
    <div className='hoverCards'>
      {cards?.map((card) => (
        <div
          key={card?.id}
          onMouseEnter={() => slideRight(card?.id)}
          onMouseLeave={() => slideRight(0)}
          className={
            rightId !== 0 && card?.id === rightId + 1
              ? "sliderRightAnimation"
              : ""
          }>
          <span>{card?.personId}</span>
          <img src={`assets/images/hoverCard/${card?.img}.png`} alt='' />
          <h2>Aparna Devi</h2>
          <h4>33Yrs, 5 feet 6 inches </h4>
          <h4>Mumbai, Maharashtra</h4>
        </div>
      ))}
    </div>
  )
}

export default HoverCard
