import React from "react"
import "./profile.scss"
import { HoverCardStaticData } from "./components/hoverCard/HoverCradStaticData"
import HoverCard from "./components/hoverCard/HoverCard"
import Carousel from "./components/carousel/Carousel"

const Profile = () => {
  return (
    <div className='profile' id='profile'>
      <article>
        <h1>
          <span className='gradient-text'>Introducing</span> our profile
        </h1>
        {HoverCardStaticData?.map((data) => (
          <div key={data?.id} className='profile-card'>
            <h3>{data?.title}</h3>
            <p>{data?.text}</p>
            <div className='profile-card-slider'>
              <HoverCard cards={data?.cards} />
            </div>
            <div className='profile-card-slider2'>
              {/* <div className='feather-left'></div> */}
              <Carousel cards={data?.cards} direction={data?.direction} />
              {/* <div className='feather-right'></div> */}
            </div>
          </div>
        ))}
      </article>
    </div>
  )
}

export default Profile
