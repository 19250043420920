import React, { useState ,useEffect,useRef} from "react"
import "./nav.scss"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { faAngleDown, faBars, faXmark } from "@fortawesome/free-solid-svg-icons"

const Nav = () => {
  const [toggle, setToggle] = useState(false)
  const [supportToggle, setSupportToggle] = useState(false)
  const navRef = useRef(null); 

  useEffect(() => {
    
    const handleOutsideClick = (event) => {
      if (navRef.current && !navRef.current.contains(event.target)) {
        setToggle(false);
        setSupportToggle(false);
      }
    };

    window.addEventListener("click", handleOutsideClick);

    return () => {
      window.removeEventListener("click", handleOutsideClick);
    };
  }, []);
  return (
    <div className='nav'>
       <div className='nav' ref={navRef}>
      <nav>
        <img src='assets/images/logo.png' alt='logo' />
        <div className={toggle ? "link show" : "link"}>
          <div>
            <ul>
              <li>
                <a href='#about'> About us</a>
              </li>
              <li>
                <a href='#profile'> Profiles</a>
              </li>
              <li>
                <a href='#stories'> Stories</a>
              </li>
              <li>
                <a href='#contact'> Contact us</a>
              </li>
            </ul>
            <div>
              <div>
                <img src='assets/images/phone.png' alt='phone' />
                <p>24x7 Help</p>
              </div>
              <div>
                <p>Customer care:</p>
                <p>+91 - 9876543210</p>
              </div>
              <div>
                <p>Get App</p>
                <img src='assets/images/playStore.png' alt='playstore' />
                <img src='assets/images/apple-logo.png' alt='apple' />
              </div>
            </div>
          </div>
        </div>
        <div className='apps'>
          <p>Get App</p>
          <img src='assets/images/playStore.png' alt='' />
          <img src='assets/images/apple-logo.png' alt='' />
          <div className={supportToggle ? "support show" : "support"}>
            <div
              onClick={() => setSupportToggle(!supportToggle)}
              className='supportCta'>
              <img src='assets/images/support.png' alt='support' />
              <FontAwesomeIcon icon={faAngleDown} className='angleDown' />
            </div>
            <div className='supportDetails'>
              <div>
                <img src='assets/images/phone.png' alt='phone' />
                <p>24x7 Help</p>
              </div>
              <div>
                <p>Customer care:</p>
                <p>+91 - 9876543210</p>
              </div>
              <div>
                <img src='assets/images/chat.png' alt='phone' />
                <p>Chat Service</p>
              </div>
              <div>
                <img src='assets/images/headphones.png' alt='phone' />
                <p>Live Support</p>
              </div>
              <div>
                <img src='assets/images/like.png' alt='phone' />
                <p>Feedback</p>
              </div>
            </div>
          </div>
        </div>
        <FontAwesomeIcon
          icon={toggle ? faXmark : faBars}
          className='bars'
          onClick={() => setToggle(!toggle)}
        />
        </nav>
        </div>
    </div>
  )
}

export default Nav
