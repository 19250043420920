import React from "react"
import "./home.scss"
import About from "../../components/about/About"
import Mathchmaking from "../../components/Mathchmaking/Mathchmaking"
import Accordion from "../../components/accordion/Accordion"
import Plan from "../../components/Plans/Plan"
import Stories from "../../components/stories/Stories"
import Profile from "../../components/profile/Profile"
import Nav from "../../components/navbar/Nav"
import Contact from "../../components/Contact/Contact"
import { faAnglesDown } from "@fortawesome/free-solid-svg-icons"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
const Home = () => {
  return (
    <>
      <Nav />

      <div
        className='hero'
        style={{
          background: 'url("assets/images/home/marriage.png") no-repeat center',
          backgroundSize: "cover",
        }}>
        <article>
          <h1>
            India's New Age
            <br /> Matrimony App
          </h1>
          <span>
            Find someone who you can easily connect with, Get married!
          </span>
        </article>
        <a href='#about'>
          <FontAwesomeIcon icon={faAnglesDown} className='double-angleDown' />
        </a>
      </div>

      <About />

      <Stories />

      <div
        className='download-banner'
        style={{ background: 'url("assets/images/home/downloadBg.png")' }}>
        <div>
          <article>
            <div>
              <h4>Looking for a life partner?</h4>
              <h2>
                Download the
                <span className='gradient-text'> Infinit Matrimony</span> App
              </h2>
              <h4>Connect with genuine, govt. ID verified profiles.</h4>
              <button>Find your Life partner now!</button>
            </div>
            <div
              className='download-banner-img'
              style={
                {
                  // background: 'url("assets/images/home/download-mobile.png")',
                }
              }>
              <img
                src='assets/images/home/download-mobile.png'
                alt='download-mobile'
              />
              <div>
                <img
                  src='assets/images/home/download-info1.png'
                  alt='info1'
                  className='info1'
                />
                <img
                  src='assets/images/home/download-info2.png'
                  alt='info2'
                  className='info2'
                />
                <img
                  src='assets/images/home/download-info3.png'
                  alt='info3'
                  className='info3'
                />
              </div>
            </div>
          </article>
        </div>
      </div>

      <div className='bride-groom'>
        <article>
          <div className='animate-img'>
            <img
              src='assets/images/home/bride-groom.png'
              alt='bride and groom'
              className='bride-groom-img'
            />
            <img
              src='assets/images/home/clouds.png'
              alt='clouds'
              className='clouds'
            />
          </div>
          <div>
            <h2>
              Reason <span className='gradient-text'>Why We Are</span> Just
              Meant For You
            </h2>
            <p>
              At Infinit Matrimony, our matrimony app, we understand the
              importance of your jeevansathi preferences. To ensure a smooth &
              quick start, we have developed a simple step registration process
              for you.
            </p>
            <div>
              <div>
                <img src='assets/images/home/bride-groom-icon1.png' alt='' />
                <p>Premium Profiles</p>
              </div>
              <div>
                <img src='assets/images/home/bride-groom-icon2.png' alt='' />
                <p>Simple Step Registration</p>
              </div>
              <div>
                <img src='assets/images/home/bride-groom-icon3.png' alt='' />
                <p>Request Contact</p>
              </div>
              <div>
                <img src='assets/images/home/bride-groom-icon4.png' alt='' />
                <p>Verified profiles</p>
              </div>
              <div>
                <img src='assets/images/home/bride-groom-icon5.png' alt='' />
                <p>Build Your own Biodata</p>
              </div>
              <div>
                <img src='assets/images/home/bride-groom-icon6.png' alt='' />
                <p>100% privacy guaranteed</p>
              </div>
            </div>
          </div>
        </article>
      </div>
      <div>
        <Mathchmaking />
      </div>

      <Profile />

      <Plan />

      <Accordion />

      <Contact />
    </>
  )
}

export default Home
