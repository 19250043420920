import React from "react"
import "./carousel.scss"
import { CarouselData } from "./Carousel-StaticData"
// import { Splide, SplideSlide } from "@splidejs/react-splide"

const Carousel = () => {
  return (
    <div className='carousel'>
      <div className='wrapper'>
        {CarouselData?.map((data, i) => (
          <img
            src={`assets/images/carousel/${data?.img}.png`}
            alt={data?.img}
            key={data?.id}
          />
        ))}

        {/* <Splide
          aria-label='My Favorite Images'
          options={{
            rewind: true,
            // autoplay: true,
            arrows: false,
            type: "loop",
            width: "100%",
            height: "500px",
            perPage: 5,
            perMove: 1,
            drag: true,
            gap: "1rem",
            easing: "linear",
            focus: "center",
            pagination: false,
            interval: 400,
            autoScroll: {
              speed: 100,
            },
            breakpoints: {
              1800: {
                perPage: 4,
              },
              1400: {
                perPage: 3.5,
              },
              1250: {
                perPage: 3,
              },
              1050: {
                perPage: 2.7,
              },
              950: {
                perPage: 2.4,
              },
              850: {
                perPage: 2.2,
              },
              750: {
                perPage: 2,
              },
              700: {
                perPage: 1.7,
              },
              600: {
                perPage: 1.5,
              },
              500: {
                perPage: 1.2,
              },
              400: {
                perPage: 1,
              },
            },
          }}>
          {CarouselData?.map((data, i) => (
            <SplideSlide>
              <img
                src={`assets/images/carousel/${data?.img}.png`}
                alt={data?.img}
                key={data?.id}
              />
            </SplideSlide>
          ))}
        </Splide> */}
      </div>
    </div>
  )
}

export default Carousel
