import React from "react";
import "./style.scss";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faArrowUp } from "@fortawesome/free-solid-svg-icons";
const scrollToTop = () => {
  window.scrollTo({ top: 0, behavior: "smooth" });
};
const Footer = () => {
  const currentyear = new Date().getFullYear();
  console.log(currentyear);
  return (
    <div className="footer-top">
      <article>
        <div className="icon">
          <img
            style={{ width: "250px" }}
            src="assets/images/footer/main.png"
            alt="Assets"
          />
          <h3>Social Links</h3>
          <div>
            <img src="assets/images/footer/Facebook.png" alt="Assets" />

            <img src="assets/images/footer/Instagram.png" alt="Assets" />

            <img src="assets/images/footer/Twitter.png" alt="Assets" />

            <img src="assets/images/footer/Youtube.png" alt="Assets" />
          </div>
        </div>

        <div className="company">
          <ul>
            <li>Company</li>
            <li>
              <a href="#about"> About us</a>
            </li>
            <li>
              <a href="#profile"> Profile</a>
            </li>
            <li>
              <a href="#stories">Success Stories</a>
            </li>
            <li>
              <a href="#contact"> Contact us</a>
            </li>
          </ul>
        </div>

        <div className="privacy">
          <ul>
            <li>Privacy</li>
            <li>
              <a>Terms of use</a>
            </li>
            <li>privacy policy</li>
          </ul>
        </div>
        <div className="footer-coll">
          <ul>Need Help?</ul>
          <div>
            <img src="assets/images/phone.png" alt="phone" />
            <p>24x7 Help</p>
          </div>
          <div>
            <p>Customer care:</p>
            <p>+91 - 9876543210</p>
          </div>
          <div>
            <p>Get App</p>
            <img src="assets/images/playStore.png" alt="playstore" />
            <img src="assets/images/apple-logo.png" alt="apple" />
          </div>
        </div>
        <div className="arrow" onClick={scrollToTop}>
          <FontAwesomeIcon icon={faArrowUp} />
          <button className="button">Back go</button>
        </div>
      </article>

      <div className="footer-bot">
        <p> Copyright @ {currentyear} . All rights reserved by Aptonworks.</p>
        <p>
          This website is strictly for matrimonial purpose only and not a dating
          website.
        </p>
      </div>
    </div>
  );
};

export default Footer;
