import React, { useEffect, useRef, useState } from "react"
import "../Mathchmaking/Mathchmaking.scss"

function Mathchmaking() {
  const [slideIndex, setSlideIndex] = useState(1)
  const timeoutRef = useRef(null)

  useEffect(() => {
    timeoutRef.current = setTimeout(
      () => setSlideIndex((prevIndex) => (prevIndex === 3 ? 1 : prevIndex + 1)),
      10000
    )

    return () => {
      resetTimeout()
    }
  })
  const resetTimeout = () => {
    timeoutRef.current && clearTimeout(timeoutRef.current)
  }

  return (
    <div className='container'>
      <article className='article'>
        <div>
          <h1>
            AI <span className='gradient-text'>Matchmaking</span>
          </h1>
          <div className='cards'>
            <div
              className={slideIndex === 1 ? "card active-card" : "card"}
              onClick={() => setSlideIndex(1)}>
              <span></span>
              <div>
                <h4>Meet with someone who has a similar passion as you.</h4>
                <p>
                  Lorem ipsum dolor sit amet consectetur. Vitae faucibus arcu
                  vel lorem viverra morbi tincidunt amet
                </p>
              </div>
            </div>
            <div
              className={slideIndex === 2 ? "card active-card" : "card"}
              onClick={() => setSlideIndex(2)}>
              <span></span>
              <div>
                <h4>Match with only Govt. ID and Selfie-verified Profiles</h4>
                <p>
                  Lorem ipsum dolor sit amet consectetur. Vitae faucibus arcu
                  vel lorem viverra morbi tincidunt amet
                </p>
              </div>
            </div>
            <div
              className={slideIndex === 3 ? "card active-card" : "card"}
              onClick={() => setSlideIndex(3)}>
              <span></span>
              <div>
                <h4>Meet with someone who shares the Same Location</h4>
                <p>
                  Lorem ipsum dolor sit amet consectetur. Vitae faucibus arcu
                  vel lorem viverra morbi tincidunt amet
                </p>
              </div>
            </div>
          </div>
        </div>
        <img src={`assets/images/home/ai-match-img1.png`} alt='' />
      </article>
    </div>
  )
}

export default Mathchmaking
