import React from "react"
import "./style.scss"
// import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
// import {
//   faCircleCheck,
//   faCircleXmark,
//   faXmark,
// } from "@fortawesome/free-solid-svg-icons"
import { planStaticData } from "./StaticData"
import PlanCard from "./components/PlanCard"
import { Splide, SplideSlide } from "@splidejs/react-splide"

const Plan = () => {
  return (
    <div
      className='plan'
      style={{
        background: "url('assets/images/about/bg.png')",
      }}>
      <article>
        <h2>Membership Plans</h2>
        <div className='plan-cards'>
          <div className='planCard1'>
            {planStaticData?.map((data) => {
              return <PlanCard key={data?.id} {...data} />
            })}
          </div>

          <div className='planCard2'>
            <Splide
              aria-label='My Favorite Images'
              options={{
                rewind: true,
                autoplay: true,
                arrows: false,
                type: "loop",
                width: 700,
                height: "600px",
                perPage: 2,
                perMove: 1,
                // direction: direction,
                drag: true,
                gap: "1rem",
                easing: "linear",
                pagination: false,
                breakpoints: {
                  768: {
                    width: 400,
                    perPage: 1,
                    gap: "0rem",
                  },
                  499: {
                    width: 300,
                    perPage: 1,
                  },
                },
              }}>
              {planStaticData?.map((data) => {
                return (
                  <SplideSlide>
                    <PlanCard {...data} slide />
                  </SplideSlide>
                )
              })}
            </Splide>
          </div>
        </div>
      </article>
    </div>
  )
}

export default Plan
